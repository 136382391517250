/* visitnotes/mylist.css */
.calendar tr td:hover {
    background-color:#57b846;
    color: #ffffff;
   }
   .past-history-wrapper{
       color:black!important;
   }
 
   /*.popup-content .modal-header {
    background-image: -webkit-linear-gradient(top,#f5f5f5 0,#e8e8e8 100%);
    background-image: linear-gradient(to bottom,#f5f5f5 0,#e8e8e8 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0!important);
    font-size:16px;
    padding:10px;
    color:#333!important;
  }
*/
.componentcss .mylist .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    padding: 3px 5px;
    line-height: 1.42857143;
    vertical-align: middle;
    font-size: 12px;
    border: 1px solid #ddd;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.componentcss .mylist .table-responsive.dx-g-bs4-table-container {
        height: 170px !important; 
}


.componentcss .mylist thead {
    background-color: #eaeaea!important;
}

.componentcss .mylist .table-bordered, thead {
    border: 1px solid #ddd!important;
}

.componentcss .mylist thead {
    background: #f4f4f4!important;
}

/*
#recentvisitlistshort .popup-content {
    width: 50%!important;
}

//Reviewin
#recentvisitlistshort .popup-content {
    width: 400px!important;
}*/

/*Word Fill*/
#recentvisitlistshort .popup-content {
    width: 1000px!important;
}

#recentvisitlistshort .popup-content .mylistbody {
    height: 480px;
    padding:0px 15px!important;
}
#recentvisitlistshort .modal-footer { float:left; width:100%; }

.componentcss .mylist .input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child>.btn, .input-group-btn:first-child>.btn-group>.btn, .input-group-btn:first-child>.dropdown-toggle, .input-group-btn:last-child>.btn-group:not(:last-child)>.btn, .input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
    z-index: 0;
    float: left;
    margin-top: 0px;
    margin-bottom: 0px;
}
.nav-pills>li+li {
    margin-left: 0px;
}

/* grid/bgridServer.css */
.box
{
box-shadow: unset !important;
/*margin-top:10px !important;*/
}
.table-responsive.dx-g-bs4-table-container
{
    height:200px !important;
}

.dx-g-bs4-table-sticky {
    position: sticky;
  position: -webkit-sticky;
  top: 0; /* required */
  border: 1px solid #ddd;;
}
thead{
    background: #f4f4f4;
    border: 1px solid #ddd;
}
.table-bordered {
    border: 1px solid #ddd;
}
.position-relative .dx-g-bs4-header-cell .text-nowrap
{
    border:unset;
}
.table{
    margin-bottom:unset;
}
table>thead>tr>th {
    /*padding: 7px !important;*/
    border: 1px solid #ddd !important;
    font-size: 11px !important;
    cursor: pointer;
    font-weight:normal;
}
.highlightprev
{
    color: red;
}
.selectedBg
{
   
}
.add-edit-btn-groups {
    margin: 10px 0 15px 0;
}
.add-edit-btn-groups .btn {
    margin-right: 5px;
}   
thead {
    background-color: #eaeaea;
}
th {
    text-align: left;
}
.dx-rg-bs4-table-header-title {
    font-weight: bold;
}
/*.list-box-border{
    border-bottom: 1px solid #444;
    margin: 20px 0 10px 0;
} */
.table-responsive .dx-g-bs4-table-container {
    border: 1px solid #ddd;
}
.modal-title{
    background-color: transparent;
    float:none;
}
.form-control{
    border: 1px solid #ddd;
}
.table tbody tr td {
    padding: 5px;
}
.input, select{
  padding:5px;
}
.modal-footer {
    margin-top: 20px;
}

/*.table-border{
    border: 1px solid rgb(196, 196, 196);
    padding: 5px;
    margin-bottom: 30px;
}
.table-border2{
    border: 1px solid rgb(196, 196, 196);
    padding: 5px;
}*/
.mylist .calendar tbody>tr:nth-of-type(2n) {
    background-color: #fff;
    color: #333;
}
.modal-footer-bottom-margin {
    margin-bottom: 50px;
}
.add-edit-btn-groups {
    margin: 0;
}
.header-hr{
    border: 1px solid #ddd;
    margin: 5px 0;
}

.modal-title-2{
    font-size: 20px;
}
.maping-textarea{
    height: 200px;
    width: 100%;
}
.checkbox-left{
    float: left;

}
.overflow-body{
    height: 400px;
    overflow: scroll;
}
.left-class{
    float: left;
}
.font-normal{
    font-weight: normal;
}

/* inbox.css */
..popup-content {
    width: 99%!important;
}
ul.nav-menu-list-style .nav-header {
    border-top: 1px solid #FFFFFF;
    border-bottom: 1px solid #e8e8e8;
    display: block;
    margin: 0;
    line-height: 36px;
    padding: 0px 8px;
    font-size: 15px;
    font-weight: 300;
}
ul.bullets {
    /*list-style: inside disc;*/
    color:#333;
}
.newdesign a{ color:#333333;}

.navpat ul li a{ color:#333333; list-style: inside disc; }

.navpat ul li ul li a{ color:#333333; list-style: inside disc; }

.listinbox
{
    margin-right: 0px;
    font-size: 12px;
    padding: 0px;
    clear: both;
    line-height: 4px;
}
.listbox label {
    margin-bottom: 3px;
}

.listinbox span
{
    margin-left:10px; float:left; margin-top:-2px;     line-height: 18px;
}

.listbox-title {
    font-size: 14px;
    color: #000000;
    padding: 10px 0px 5px 0px;
    font-weight: bold!important;
}
.popup-content .react-datepicker__input-container input
{
    margin-top: 0px!important;
}

ul.nav-menu-list-style> li a {
    border-top: 0px solid #FFFFFF;
    border-bottom: 0px solid #e8e8e8;
}
.chkdet { padding: 2px 10px; margin-top:8px; margin-left:5px; }
/* correspondence.css */
.selectedBg {
    /*background-color: #337ab7;*/
    background-color: #57b846!important;
    color: white;
}
.record-note-wrapper .table-responsive.dx-g-bs4-table-container
{
    height:300px !important;
}
.record-note-wrapper .clearfix.card-footer.dx-g-bs4-paging-panel
{
    margin-top:30px !important;
}
.record-note-wrapper{
    /*padding: 20px 15px ;
    padding: 5px;*/
    margin-top: 15px;
    border: 1px solid #dddddd;
}
.nomalcss{
    background-color: red;
}
.no-left-padding{
    padding: 0;
}

/* reminder.css */
.commongridclass .table-responsive.dx-g-bs4-table-container
{
    height:230px !important;
}
.popup-content
{width: 50%!important;}

/*.width-adjust{
  width: 50%!important;
}

.popup-content .modal-header {
  background-image: -webkit-linear-gradient(top,#f5f5f5 0,#e8e8e8 100%);
  background-image: linear-gradient(to bottom,#f5f5f5 0,#e8e8e8 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0!important);
  font-size:16px;
  padding:10px;
}
.modal-body {
  padding: 0px 5px!important;
  margin: 0px!important;
}

.popup-content .popup-content
{width: 50%!important;}

.popup-content table th {
    background-color: #f4f4f4;
    font-weight: 600;
    padding: 0px;
    font-size: 12px;
    border-color: #666;
    vertical-align: unset;
    padding: 0px 0px 0px 0px!important;
}

.popup-content table th input{ font-size: 12px; font-weight:normal; height:22px!important; border:0px; }
*/
.displayit
{
  display: block;
}
.displayoff
{
  display: none;
}
.react-confirm-alert-body
{
  font-size:16px;
}
.selectedBg {
  /*background-color: #337ab7;*/
  background-color: black!important;
  color: white;
}
.reminder { font-size:14px; border:0px; padding-top:10px!important;  }
.reminder label { margin-right: 12px; }
/* visitnotes/diagonsis.css */
.section-title {
    margin-top: 0px;
}

.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden; /* prevents background page from scrolling when the modal is open */
}

.ReactModal__Overlay {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactModal__Content {
  background: white;
  width: 50rem;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  position: relative;
}

.modal-close-btn {
  cursor: pointer;
  top: 1.5rem;
  right: 1.5rem;
  position: absolute;
  width: 3rem;
  height: 3rem;
}
/*.patient-history-content .popup-content
{width: 70% !important; padding:0px!important; }

.patient-history-content .modal-header {
  background-image: -webkit-linear-gradient(top,#f5f5f5 0,#e8e8e8 100%);
  background-image: linear-gradient(to bottom,#f5f5f5 0,#e8e8e8 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0!important);
  font-size:16px;
  padding:10px;
}
.patient-history-content .modal-body {
  padding: 0px 5px!important;
  margin: 0px!important;
}*/

.patient-history-content .condition-type {
  display: flex;
  margin: 10px 0px 10px 0px;
  font-weight: 400;
}

.patient-history-content .add-to-my-favourites {
  margin-left: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.patient-history-content .section-title { margin-top:10px;}
/* familysocialhostory/style.css */
.col-padding{
    margin-bottom:  5px;
}
.div-margin{
    margin: 20px 0 0 0;
}
.answer-table tr{
    padding: 5px;
}
.answer-table td{
    padding: 5px;
}
.occtext { color:#666666; }
.modal-body label {
    font-size: 12px;
    vertical-align: unset;
}
.col-form-label {
    font-size: 12px;
    vertical-align: unset;
}
.link { margin-top:3px; }
.link a{ color:#ffffff; font-size:14px!important; padding-left:10px!important; margin-left: 10px;  border-left: 1px solid #ffffff; }
.link a:first-child { border:0px; }
/* add-patient-form.css */
.new-patient-wrapper .panel {
  width: 100%;
  background-color: white;
}

.input-group {
  z-index: 0;
}
.input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child>.btn, .input-group-btn:first-child>.btn-group>.btn, .input-group-btn:first-child>.dropdown-toggle, .input-group-btn:last-child>.btn-group:not(:last-child)>.btn, .input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
  z-index: 0;
}

.shrink .react-datepicker-ignore-onclickoutside{
  width: 100%;
}

.shrink .react-datepicker__input-container{
  width: 100%;
}

.form-group {
  margin: 0px;
  /* border-right: 1px solid #ddd; */
}

.shrink .react-datepicker__month-container {
  width: 100px;
}

#addPatientForm  label{
	font-size: 12px;
    font-weight: 400;
}
.cus-wid {
  flex: 0 0 13.666667%!important;
  max-width: 13.666667%!important;
  width: 13.666667%!important;
}
.modal-body label {
  font-size: 12px;
  vertical-align: unset;
}
/* immunisations.css 
.componentcss .componentcssimm .table-responsive.dx-g-bs4-table-container
{
    height:430px !important;
}*/

.componentcss .componentcssimm .popup-content
{width: 50%!important;}

.componentcss .componentcss table th {
    background-color: #f4f4f4;
    font-weight: 600;
    padding: 0px;
    font-size: 12px;
    border-color: #666;
    vertical-align: unset;
    padding: 0px 0px 0px 10px!important;
}

#collapseThreepat .newpostpopup table th { padding: 3px 7px!important; font-size:12px!important; }

#collapseThreepat .newpostpopup input[type=checkbox]{
  margin-top:15px;
  }
  
#collapseThreepat .newpostpopup { max-height: 505px!important;
  overflow: hidden; }

.componentcss .componentcssimm table th input{ font-size: 12px; font-weight:normal; height:22px!important; border:0px; }

.componentcss .componentcss .modal-body {
    padding: 0px 5px!important;
    margin: 0px!important;
  }

  .immun {padding-right: 0px; padding-left: 0px; margin-left: 0px; margin-right: 0px; font-size:12px; margin-top:1px; }

  /*.componentcss .form-control { padding: 0px 12px; }*/

  .immuntext { margin-top: 5px; padding-left: 5px; margin-right: 5px; font-size: 12px; }

  .immuntcomment { width: 100%; height: 50px; border: 1px solid #cccccc; }


 /* .componentcss .table-responsive.dx-g-bs4-table-container {
    height: 450px !important;
    overflow-y: scroll;
    border: 1px solid #ddd;
}*/

.newpostpopup2 .react-datepicker__input-container input{
  width: 100%;
}
/* clinical.css */
.clinical-list-part .table-responsive.dx-g-bs4-table-container{ 
    height:430px !important;
}

.table-responsive{
    height: 350px !important;
}

#notesdiv11 .popup-content { width: 40%!important; }

.popup-content .clinicin .react-datepicker__input-container input { margin-top:-20px!important; }
/* obstertric.css */
#opencurrentrx .popup-content {
    box-shadow: none!important;
    background: transparent!important;
}

#collapseThreepat .px-3 .popup-content {
    box-shadow: none!important;
    background: transparent!important;
}

.react-datepicker__input-container input { }

.obspopup {
    margin: 0px 10%;
    background: #ffffff;
    float: left;
    border-radius: 30px;
}
.obsoutcome {
    margin: 0px 14%;
    background: #ffffff;
    float: left;
    border-radius: 30px;
}
.obsoutcome2 {
    margin: 0px 14%;
    background: #ffffff;
    float: left;
    border-radius: 30px;
}
.obsoutcome3 {
    margin: 0px 20%;
    background: #ffffff;
    float: left;
    border-radius: 30px;
}

.gesdetails .dateic1 { margin-left:0px!important; position: absolute; top: 2px; left: 22px; z-index: 9; }
.gesdetails .dateic { margin-left:-15px!important;  }
.gesdetails .datePicker { padding-left: 23px!important;  }
/* epc.css */
.lettertable{
    width: 100%;
}

.lettertable td {
    padding: 10px;
}
.tasktable .table-responsive.dx-g-bs4-table-container {
    height: 335px !important;
    overflow-y: scroll;
    border: 1px solid ddd;
}
.panspace1
{
 background-color: grey !important;
 height: 34px;
 
}
.leftmenu .nav-item{ width:100%; }
.leftmenu .nav-item { width:100%; background-color: #44514E; }
/* investigationreport.css */

.investigationTable >tbody>tr:nth-of-type(even) {
    background-color:none;
}

.investigationTable .firstColor {
    background-color: #f4f4f4;
}

.investigationTable .secondColor {
    background-color: #ddebf6;
}



.investigationTable th{
    background-color: #f4f4f4;
    font-size: 11px;
    padding: 3px 5px!important;
    border-width: 2px;
    line-height: 1.42857143;
    border-color: #fefefe;
    font-weight: bold;
}

.investigationTable th input[type=checkbox], input[type=radio] {  margin-top:0px; }

.investigationTable .selectedBg{
    background-color: #4680ff;
    color: #fff;
}

.investigationTable .yearSeperator{
    border-top:3px solid #d60d0d;
}


.addinvestigation .react-datepicker__input-container input {
    /* width: 100%; */
    width: 109px;
}
.investigationTable  .firstColor:hover{
    background-color: #337ab7!important;
    color: #fff!important;
}
.investigationTable  .secondColor:hover{
    background-color: #57b846!important;
    color: #fff!important;
}
#notesdiv5 .invesvalue .popup-content {
    width: 70%!important;
}
#notesdiv5 .popup-content {
    width: 70%!important;
}
#notesdiv5 .form-control {
     padding: 0px 0px 0px 12px;
     width:100%;
}

.addinvestigation .form-group .col-sm-6 { margin: 10px 0px 0px;}

#Graphdetails .row {
    display: flex!important;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
#Graphdetails label{ text-align:left; }


/* investigationTable.css */
table tbody tr:nth-of-type(even) {
    background-color: #f4f4f4;
}
/*.observation td{
    background-color: #f4f4f4;
}*/
.pointer {cursor: pointer;}

.selectedBg {
    /*background-color: #337ab7;*/
    background-color: #57b846;
    color: white;
}

.observation .selectedTdBg{
background-color: #dedede !important;;
 

}

.boldd{
    font-weight: 600;
}

#p_wrap, #Investigationdetails {
    white-space: pre-line;
  }
.makeapp .btn{
    margin:5px;
    transition: background-color .5s ease-out;
    border-width:1px;
    border-color:black;
    font-size:calc(0.65em + .7vmin);
    padding: 0px 10px;
  }
  
.makeapp .btn:hover{
    color:white;
  }
.makeapp .btn-o.btn-blue {
    background-color: transparent;
    color: #0275d8;
}
.makeapp .btn-o {
    background-color: transparent;
    border-width: 1px;
}
/* addinvestigation.css */
.addinvestigation .form-group{
    padding-bottom:0px;
    padding-top:0px;
    
}

/* medication.css */
.btn {
    background-color: white;
}
.hidden {
    display:none;
 }
 .show{
    display:block;
 }
 .meds {
    background-color: white;
 }
 .componentcss{
    height:auto !important;
 }
 .componentcss .nav-fill .nav-item a{
   color:#666666;
}
.componentcss .nav-fill .nav-item:hover{
   color:#666666;
}
 .dx-rg-bs4-table-header-title{
    font-size:14px;
 }
 table > thead > tr > th {
    font-size: 14px;
 }
 table > thead >{
    background-color: #ddd;
 }
 .table tbody tr td {
    padding: 5px;
 }
 .medallergy1 .medallergy .table-responsive.dx-g-bs4-table-container
 {
     height:240px !important;
     width:100% !important;
 }
 
 .scndtbl {
   border-collapse: collapse;
 }
 .scndtbl, th, td {
   border: 1px solid black;
 }

 .scndtbl td {

      height: 50px;
      vertical-align: bottom;
 }
 
 .nav-fill .nav-item {
   flex: none;
   font-size: 12px!important;
   padding: 10px;
   border: 1px solid #ddd;
   letter-spacing: 0.5px;
   background: #f4f4f4;
   color: #666666;
   border-bottom: 0px;
}
#medicationpanel .popup-content 
{
   width:100%!important;
}
.LineGraph_graphContainer__2bTfo {
    width: 50vw;
    height: 50vh;
    background: white;
    padding: 30px 20px 10px 20px;
    border-radius: 10px;
    box-shadow: 0px 10px 0px -5px rgba(0,0,0,0.3);
}

@media only screen and (max-width: 600px) {
    .LineGraph_graphContainer__2bTfo {
        width: 80vw;
    }
}
/* abservations.css */
table tbody tr:nth-of-type(even) {
    background-color: #f4f4f4;
}
/*.observation td{
    background-color: #333;
}*/
.observation tbody>tr:nth-of-type(odd) {
  background-color: #ffffff!important;
}
.observation tbody>tr:nth-of-type(even) {
  background-color: #eaf8e6!important;
}
.observation th{
    background-color: #333;
}
.pointer {cursor: pointer;}

.selectedBg {
    /*background-color: #337ab7;*/
    background-color: #57b846;
    color: white;
}

.observation .selectedTdBg{
background-color: #dedede;


}

.boldd{
    font-weight: 600;
}

.cellwidth { padding: 5px; min-width: 90px; font-size: 12px;}

.cellhead {  padding: 2px 5px!important; min-width: 200px; }

.cellheadoth {  padding: 2px 5px!important; }
/* pasthistory.css */
.patient-history-content {
  margin: 10px;
}

.past-history-action-button {
  padding: 2px 10px;
  margin-right: 5px;
  margin-top: 4px;
  background-color: #ffffff;
  font-size: 12px;
}
#notesdiv14 .visit-notes-wrapper .add-condition-button {
  height: auto;
    position: absolute;
    right: 17px;
    margin-top: 10px;
    background: #44514E;
    width: auto;
    padding: 3px 10px;
    border-radius: 0px 5px 5px 0px;
}

#notesdiv14 .visit-notes-wrapper .add-condition-button a{
  color:#ffffff!important;
  margin-bottom: 0px!important;
}

.phistory label {
  margin-top: 0px!important;
}

#notesdiv14 .add-condition { margin-top:10px; }

#notesdiv14 .newpostpopup input[type=checkbox] {
  margin-top: 0px;
}

.past-history-list {
  font-size: 11px;
}

.past-history-list table {
  width: 100%;
}

.past-history-list table th {
  background-color: #f4f4f4;
  font-weight: 600;
  padding:0px;
  font-size: 12px;
  border-color: #666;
  vertical-align:unset;
  padding: 0px 0px 0px 0px!important; 
}

.gcomment { margin:10px 0px;}
.gcomment label { font-size:16px; color:#666666; padding-bottom:5px;  }
.gcomment textarea { font-size:12px; width:100%; }

.confidtext { float:left; margin-top:12px; }
.confidtext label { float:left; font-weight:normal; margin-top:3px; margin-left:5px; }
.confidtext input { float:left; font-weight:normal; }

.past-history-list table th input{ font-size: 12px; font-weight:normal; height:22px!important; border:0px; }


.past-history-list table tr th:nth-child(2) {
 width:172px;
}
.past-history-list table tr th:nth-child(4) {
  width:170px;
}
.past-history-list table tr th:nth-child(4) {
  width:170px;
}

.past-history-list table tr td:nth-child(2) {
  width:169px;
  overflow:hidden;
 }
 .past-history-list table tr td:nth-child(4) {
   width:169px;
   overflow:hidden;
 }
 .past-history-list table tr td:nth-child(4) {
   width:169px;
   overflow:hidden;
 }


.past-history-list table td {
  padding:10px;
  font-size: 14px;
  border-color: #666;
}

.past-history-list-part {
 /* width: 48%; */
  float: left;
  /*margin-left: 10px;*/
}

.past-history-list-part-selector {
  float: left;
  margin-left: 10px;
  padding-top: 2%;
  position: absolute;
  right:-12px;
  top:0px;
}

.past-history-list-part {
  /*overflow-x: scroll;*/
}


.past-history-list-part .table-responsive.dx-g-bs4-table-container
{
    height:330px !important;
    min-height:330px;
}

.past-history-list-part .add-to-diagnosis-checkbox {
    text-align: left;
    margin-top: 10px!important;
  }

.pastbg { background-color: #f4f4f4; float:left; }

.past-history-list-part .popup-content
{width: 40%!important;}
/* addpatienthistory.css */
.datepicker-holder {
  display: flex;
}

.datepicker-holder .today-date {
  width: 150px !important;
}

.datepicker-holder input[type='text'] {
  width: 50px;
  margin-right: 20px;
  border: none;
  border-bottom: 1px solid #cccccc;
}

.past-history-wrapper .datePicker,
.past-history-wrapper .form-control {
  border: 0px;
  border-radius: 0px;
  border-bottom: 1px solid #cccccc;
  margin-top: -20px;
  margin-bottom: 13px;
  padding: 0px;
  /* padding: 0px 12px; */
}


.modal-body {

  padding: 0px;
}


.react-datepicker-wrapper {
  float: left
}

.datePicker {
  margin-top: -14px;

}

.checkbox1 {
  margin-right: -10px;
  margin-top: -14px;
  float: right;
}
/* visitnotes/tab/tab.css */
.up {
  /*margin-top: 10px;
  background:#f3f1f1;
  padding: 5px;*/
  background-image: linear-gradient(to bottom,#f5f5f5 0,
#e8e8e8 100%);
background-repeat: repeat-x;


}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #ffffff!important;
    font-size: 16px;
    letter-spacing:1px;
}
.nav-link
{
    margin-left:0px;
        font-size: 17px;
}
.table tbody tr td {
    padding: 10px;
    font-size: 12px;
}


.popup-content .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #ffffff!important;
    font-size: 16px;
    letter-spacing:1px;
	background-color:#1ebea5;
	border: 0px solid #dddddd;
}
.componentcss .popup-content a{
    color: #ffffff!important;
    /*background-color: #44514E;*/
}
.popup-content .modal-body .form-group label {
    margin: 5px;
}
.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 10px;
}

.invotext  .form-group label {
    margin-top: 0px!important;
}
.nav > li > a:hover, .nav > li > a:active, .nav > li > a:focus {
    /* color: #444; */
    background: #1ebea5;
}
.nav-tabs>li>a:hover {
    border-color: #1ebea5 #1ebea5 #1ebea5;
}
.nav-pills > li > a {
    border-radius: 0;
    border-top: 0px solid transparent;
    color: #444;
}
.modal-body {
    padding: 0px 0px!important; 
    margin: 0px!important;
}

/* visitnotes/visitnotes.css */
.overflow {
    overflow-y: scroll;
    height: 150px;
  }

  .visit-notes-wrapper {
    margin: 10px;
  }
  .rdw-editor-main{
    height: 410px;
    overflow: hidden;
  }
  .visit-notes-wrapper .section-title {
    background-color: #f4f4f4;
    font-size: 16px;
    padding: 5px 15px;
    font-weight: 400;
  }

  /*.visit-notes-wrapper .add-condition-input {
    border: 0px;
    border-radius: 0px;
    border-bottom: 1px solid #cccccc;
    margin-top: 2px;
  }
*/
  .visit-notes-wrapper .add-condition-button {
    display: flex;
    align-items: center;
    justify-content: left;
    height: auto;
    padding: 3px 10px;
  }

  .condition-list {
    margin-top: 0px;
  }

  .condition-list .condition-list-item {
    border: 0px;
    border-radius: 0px;
    border-bottom: 0.5px solid #cccccc;
  }

  .condition-list-item {
    margin-left: 5px;
    margin-right: 5px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 5px;
  }

  .condition-type {
    display: flex;
    margin-left: 0%;
    font-weight: 400;
  }

  .condition-type-item {
    margin-right: 30px;
  }

  .condition-type .condition-type-item label,
  .add-to-my-favourites label,
  .add-to-todays-reason-checkbox label,
  .add-to-diagnosis-checkbox label {
    font-weight: 400;
    margin-left: 17px;
    margin-top: -19px;
  }

  .add-to-my-favourites {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .add-to-todays-reason-checkbox,
  .add-to-diagnosis-checkbox {
    text-align: left;
    margin-top: 10px;
  }

.calendar .firstColor {
    background-color: #f4f4f4 !important;
}

.calendar .secondColor {
    background-color: #ddebf6 !important;
}

.calendar .yearSeperator{
    border-top:3px solid #d60d0d !important;
}
.modal-body{
  padding: 5px 15px;
}
.table-responsive.dx-g-bs4-table-container
{
  height:250px !important;
  overflow-y: scroll;
  border: 1px solid #ddd;
}
.panspace nav a { font-size:14px; }
.panspace nav a:first-child { font-size:14px; margin-right:10px; padding-right:10px; border-right: 1px solid #ffffff; }
/* visitnotes/print.css */
/* @media print {
    body * {
      visibility: hidden;
    }
    #section-to-print, #section-to-print * {
      visibility: visible;
      width: auto;
      height: auto;
      top: 40px;
      padding: 10px;
       min-width: 595px;
      min-height: 842px; 
      overflow: visible;
    }
    #section-to-print {
       position: absolute; 
      left: 0;
      top: 0;
    }
  } */
/* visitnotes/medicalcertiticate/certificate.css */
.alignright{
    color:red !important;
}

.popup-content .react-datepicker__input-container input {
    /*margin-top: 20px!important;*/
}

/* visitnotes/womenhistory.css */
.patient-history-content {
  margin: 10px;
}

.past-history-action-button {
  padding: 2px 10px;
  margin-right: 5px;
  margin-top: 4px;
  background-color: #ffffff;
  font-size: 12px;
}
#notesdiv14 .visit-notes-wrapper .add-condition-button {height:auto; }

#notesdiv14 .add-condition { margin-top:10px; }

#notesdiv14 .newpostpopup input[type=checkbox] {
  margin-top: 0px;
}
.Cervicalpopup .modal-body .form-group label {
  margin-top: 0px!important; 
}

.past-history-list {
  font-size: 11px;
}

.past-history-list table {
  width: 100%;
}

.past-history-list table th {
  background-color: #f4f4f4;
  font-weight: 600;
  padding:0px;
  font-size: 12px;
  border-color: #666;
  vertical-align:unset;
  padding: 0px 0px 0px 0px!important; 
}

.gcomment { margin:0px 0px;}
.gcomment label { font-size:14px; color:#666666; padding-bottom:0px;  }
.gcomment textarea { font-size:12px; width:100%; }

.confidtext { float:left; margin-top:12px; }
.confidtext label { float:left; font-weight:normal; margin-top:3px; margin-left:5px; }
.confidtext input { float:left; font-weight:normal; }

.past-history-list table th input{ font-size: 12px; font-weight:normal; height:22px!important; border:0px; }


.past-history-list table tr th:nth-child(2) {
 width:172px;
}
.past-history-list table tr th:nth-child(4) {
  width:170px;
}
.past-history-list table tr th:nth-child(4) {
  width:170px;
}

.past-history-list table tr td:nth-child(2) {
  width:169px;
  overflow:hidden;
 }
 .past-history-list table tr td:nth-child(4) {
   width:169px;
   overflow:hidden;
 }
 .past-history-list table tr td:nth-child(4) {
   width:169px;
   overflow:hidden;
 }


.past-history-list table td {
  padding:10px;
  font-size: 14px;
  border-color: #666;
}

.past-history-list-part {
 /* width: 48%; */
  float: left;
  /*margin-left: 10px;*/
}

.past-history-list-part-selector {
  float: left;
  margin-left: 10px;
  padding-top: 2%;
  position: absolute;
  right:-12px;
  top:0px;
}

.past-history-list-part {
  /*overflow-x: scroll;*/
}


.past-history-list-part .table-responsive.dx-g-bs4-table-container
{
    height:330px !important;
    min-height:330px;
}

.past-history-list-part .add-to-diagnosis-checkbox {
    text-align: left;
    margin-top: 10px!important;
  }

.pastbg { background-color: #f4f4f4; float:left; }

.past-history-list-part .popup-content
{width: 40%!important;}


/* visitnotes/combindpatienthistory.css */
.patient-detail-nav-item {
    cursor: pointer;
  }
  
  .combined_highlight{
    background-color: #1ebea5!important;
    color:#fff;

    /* -webkit-box-shadow: 0px -2px 11px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px -2px 11px -3px rgba(0,0,0,0.75);
    box-shadow: 0px -2px 11px -3px rgba(0,0,0,0.75); */
  }

  .tree-toggle{
    cursor: pointer;
  }

  .red_highlight{
    background-color:#ff0000 !important; 
    color:#ffffff !important;
  }
  .white_highlight{
    background-color:#ffffff; 
    color:#000000;
  }
/* grid/finalisevisits.css */
.add-servicenotes {
    margin: 2px 10px;
    padding: 0px 8px;
  }
  .invotext {
    padding: 20px!important;
}
/* appointment/Tags.css */
/******************* BASIC STYLING **********************/
.scroll_tabs_container {
  position: relative;
  top: 0px;
  left: 0px;
  right: 0px;
  text-align: left;
  height: 40px;
  /*margin-bottom: 10px;*/
}

ul.scroll_tabs_container {
  list-style: none;
}

.scroll_tabs_container div.scroll_tab_inner {
  height: 40px;
}

.scroll_tabs_container div.scroll_tab_inner span, .scroll_tabs_container div.scroll_tab_inner li {
  padding-left: 20px;
  padding-right: 20px;
  line-height: 40px;
  font-size: 14px;
  background-color: #ccc;
  color: #333333;
  cursor: pointer;
  margin-left: 10px;

  -webkit-transform: perspective(100px) rotateX(30deg);
  -moz-transform: perspective(100px) rotateX(30deg);

}

.scroll_tabs_container div.scroll_tab_inner li {
  display: -moz-inline-stack;
  display: inline-block;
  *display: inline;
  list-style-type: none;
}

.scroll_tabs_container div.scroll_tab_inner span.scroll_tab_left_finisher {
  padding: 0px;
  width: 0px;
}

.scroll_tabs_container div.scroll_tab_inner span.scroll_tab_right_finisher {
  padding: 0px;
  width: 0px;
}

.scroll_tabs_container .scroll_tab_left_button {
  height: 40px;
  /*background-color: #ffffff;*/
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scroll_tabs_container .scroll_tab_left_button::before {
  content: "\25C0";
  line-height: 40px;
  padding-left: 5px;
}

.scroll_tabs_container .scroll_tab_left_button_over {
  background-color: #999999;
}

.scroll_tabs_container .scroll_tab_left_button_disabled {
  color: #AAAAAA;
  background-color: #ffffff;
}

.scroll_tabs_container .scroll_tab_right_button {
  height: 40px;
  /*background-color: #ffffff;*/
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scroll_tabs_container .scroll_tab_right_button::before {
  content: "\25B6";
  line-height: 40px;
  padding-left: 5px;
}

.scroll_tabs_container .scroll_tab_right_button_over {
  background-color: #999999;
}

.scroll_tabs_container .scroll_tab_right_button_disabled{
  color: #AAAAAA;
  background-color: #ffffff;
}

/****************** LIGHT THEME **************************/
.scroll_tabs_theme_light {
  height: 42px;
}

.scroll_tabs_theme_light div.scroll_tab_inner {
  height: 42px;
}

.scroll_tabs_theme_light div.scroll_tab_inner span, .scroll_tabs_theme_light div.scroll_tab_inner li {
  padding-left: 20px;
  padding-right: 20px;
  line-height: 40px;
  font-size: 14px;
  /*background-color: #ffffff;
  border-left: 1px solid #999999;
  border-top: 1px solid #999999;
  border-bottom: 1px solid #999999;*/
  color: #333333;
  cursor: pointer;
}
.paynowlab  { color: #666666; }

.paynowlab .popup-content label {
  margin-top: 20px;
}

.paynowlab .popup-content .modal-body .form-group label {
  margin: 0px;
}

.popup-content .paynowlab table th { background: #666; }

.creatac { color: #666666; }

.invotext { color: #666666; }

.invotext  .form-group label {
  margin: 0px!important; padding:2px 5px;
}


.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_first, .scroll_tabs_theme_light div.scroll_tab_inner li.scroll_tab_first {
  border-left: 0px;
}

.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_left_finisher {
  padding: 0px;
  width: 0px;
  /*background-color: #ffffff;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;*/
  display:none!important;
}

.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_right_finisher {
  padding: 0px;
  width: 0px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  /*background-color: #ffffff;
  border-left: 0px;
  border-right: 1px solid #999999;*/
  display:none!important;
}

.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_over, .scroll_tabs_theme_light div.scroll_tab_inner li.scroll_tab_over {
  background-color: #999999;
}

/*.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_first_over {
  background-color: #999999;
}

.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_left_finisher_over {
  background-color: #999999;
}

.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_right_finisher_over {
  background-color: #999999;
}*/

/*.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_left_finisher_selected {
  background-color: #AAAAAA;
}

.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_right_finisher_selected {
  background-color: #AAAAAA;
}*/


.scroll_tabs_theme_light .scroll_tab_left_button {
  height: 42px;
 /* background-color: #ffffff;*/
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 0px solid #999999;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scroll_tabs_theme_light .scroll_tab_left_button::before {
  content: "\25C0";
  line-height: 40px;
  padding-left: 5px;
}

.scroll_tabs_theme_light .scroll_tab_left_button_over {
  background-color: #999999;
}

.scroll_tabs_theme_light .scroll_tab_left_button_disabled {
  color: #AAAAAA;
  background-color: #ffffff;
}

.scroll_tabs_theme_light .scroll_tab_right_button {
  height: 42px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  /*background-color: #ffffff;
  border: 1px solid #999999;*/
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scroll_tabs_theme_light .scroll_tab_right_button::before {
  content: "\25B6";
  line-height: 40px;
  padding-left: 5px;
}

.scroll_tabs_theme_light .scroll_tab_right_button_over {
  background-color: #999999;
}

.scroll_tabs_theme_light .scroll_tab_right_button_disabled{
  color: #AAAAAA;
  background-color: #ffffff;
}

.scroll_tabs_theme_light div.scroll_tab_inner span.tab_selected, .scroll_tabs_theme_light div.scroll_tab_inner li.tab_selected {
  background-color: #AAAAAA;
}

/*.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_first_selected {
  background-color: #AAAAAA;
}*/

/****************** DARK THEME **************************/
.scroll_tabs_theme_dark {
  height: 42px;
}

.scroll_tabs_theme_dark div.scroll_tab_inner {
  height: 42px;
}

.scroll_tabs_theme_dark div.scroll_tab_inner span, .scroll_tabs_theme_dark div.scroll_tab_inner li {
  padding-left: 20px;
  padding-right: 20px;
  line-height: 40px;
  font-size: 14px;
  background-color: #333333;
  border-left: 1px solid #222222;
  border-top: 1px solid #222222;
  border-bottom: 1px solid #222222;
  color: #FFFFFF;
  cursor: pointer;
}

.scroll_tabs_theme_dark div.scroll_tab_inner span.scroll_tab_first, .scroll_tabs_theme_dark div.scroll_tab_inner li.scroll_tab_first {
  border-left: 0px;
}

.scroll_tabs_theme_dark div.scroll_tab_inner span.scroll_tab_left_finisher {
  padding: 0px;
  width: 10px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.scroll_tabs_theme_dark div.scroll_tab_inner span.scroll_tab_right_finisher {
  padding: 0px;
  width: 10px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 0px;
  border-right: 1px solid #222222;
}

.scroll_tabs_theme_dark div.scroll_tab_inner span.scroll_tab_over, .scroll_tabs_theme_dark div.scroll_tab_inner li.scroll_tab_over {
  background-color: #555555;
}

.scroll_tabs_theme_dark .scroll_tab_left_button {
  height: 42px;
  background-color: #333333;
  color: #FFFFFF;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid #222222;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scroll_tabs_theme_dark .scroll_tab_left_button::before {
  content: "\25C0";
  line-height: 40px;
  padding-left: 5px;
}

.scroll_tabs_theme_dark .scroll_tab_left_button_over {
  background-color: #666666;
}

.scroll_tabs_theme_dark .scroll_tab_left_button_disabled {
  color: #444444;
  background-color: #333333;
}

.scroll_tabs_theme_dark .scroll_tab_right_button {
  height: 42px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #333333;
  border: 1px solid #222222;
  color: #FFFFFF;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scroll_tabs_theme_dark .scroll_tab_right_button::before {
  content: "\25B6";
  line-height: 40px;
  padding-left: 5px;
}

.scroll_tabs_theme_dark .scroll_tab_right_button_over {
  background-color: #666666;
}

.scroll_tabs_theme_dark .scroll_tab_right_button_disabled{
  color: #444444;
  background-color: #333333;
}

.scroll_tabs_theme_dark div.scroll_tab_inner span.tab_selected, .scroll_tabs_theme_dark div.scroll_tab_inner li.tab_selected {
  background-color: #666666;
}

 

.scroll_tabs_theme_light div.scroll_tab_inner  .scroll_tab_active_other {
 /*background-color: #e8e8e8;
color: #000000;
-webkit-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);*/

height:50px;
  background: #44514e;
  color:#ffffff;
  border-radius: 4px;
  border:1px solid #ccc;
  margin:0 10px 0;
  box-shadow: 0 0 2px  #fff inset;


 -webkit-transform: perspective(100px) rotateX(30deg);
 -moz-transform: perspective(100px) rotateX(30deg);

}
.scroll_tabs_theme_light div.scroll_tab_inner .scroll_tab_active_male {
  /*background-color: #e8e8e8;
 color: #000000;
 -webkit-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
 -moz-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
 box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);*/
 
 height:50px;
   background:#198efc;
   color:#ffffff;
   border-radius: 4px;
   border:1px solid #ccc;
   margin:0 10px 0;
   box-shadow: 0 0 2px  #fff inset;
 
 
  -webkit-transform: perspective(100px) rotateX(30deg);
  -moz-transform: perspective(100px) rotateX(30deg);
 
 }
 .scroll_tabs_theme_light div.scroll_tab_inner  .scroll_tab_active_female {
  /*background-color: #e8e8e8;
 color: #000000;
 -webkit-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
 -moz-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
 box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);*/
 
 height:50px;
   background: #e756cf;
   color:#ffffff;
   border-radius: 4px;
   border:1px solid #ccc;
   margin:0 10px 0;
   box-shadow: 0 0 2px  #fff inset;
 
 
  -webkit-transform: perspective(100px) rotateX(30deg);
  -moz-transform: perspective(100px) rotateX(30deg);
 
 }

 .scroll_tabs_theme_light div.scroll_tab_inner  .scroll_tab_active_female a {
  color: #fff;
  text-decoration: none;
}

 .scroll_tab_active_female {
  /*background-color: #e8e8e8;
 color: #000000;
 -webkit-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
 -moz-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
 box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);*/
 
 height:50px;
   background: #e756cf;
   color:#ffffff;
   border-radius: 4px;
   border:1px solid #ccc;
   margin:0 10px 0;
   box-shadow: 0 0 2px  #fff inset;
 
 
  -webkit-transform: perspective(100px) rotateX(30deg);
  -moz-transform: perspective(100px) rotateX(30deg);
 
 }

 .scroll_tab_active_other {
  /*background-color: #e8e8e8;
 color: #000000;
 -webkit-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
 -moz-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
 box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);*/
 
 height:50px;
   background: #93908c;
   color:#ffffff;
   border-radius: 4px;
   border:1px solid #ccc;
   margin:0 10px 0;
   box-shadow: 0 0 2px  #fff inset;
 
 
  -webkit-transform: perspective(100px) rotateX(30deg);
  -moz-transform: perspective(100px) rotateX(30deg);
  
 }
.investigationTable .firstColor:hover {
  background-color: #57b846!important;
  color: #fff!important;
}

.scroll_tab_active a{ color:#ffffff; }

.popup-content .popup-content {
  width: 70%!important;
}
/* Calendar.css */
.calendar  tbody>tr:nth-of-type(even) {
      background-color: #eaf8e6;
}

/* .calendar td:first-child {background: #dddddd85} */
/*.calendar td:nth-child(2n+3) {background: #CCC}*/
.calendar th{background: #44514E;
    font-family: "Roboto",sans-serif,Montserrat-Medium;
    font-size: 11px;
    color: #fff!important;
    line-height: 1.4;
    text-transform: uppercase;
    /* box-shadow: 9px 1px 10px 1px #888888; */
    /* box-shadow: -3px 3px 10px 1px #888888; */
    /* box-shadow: -1px 0px 10px 1px #888888; */
}
.table tbody tr td {
    padding: 2px 0px 0px 10px;
    border: 1px solid #dddddd;
}

.box-header .with-border
{
        height: 40px;
    margin-top: 0px;

    top: -14px;
}
.btn-primary{
     background-color: #333333;
     color: #ffffff;
     border: 0px;
     font-size: 12px;
}
.calendarDatePicker{
    width: 10px;


}
.calendarDatePicker:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f073";
    color: #555;
    padding: 0 5px;
}


td .hiddenMore{
    display: none;
}
.ColumnContainer {
    width:110px;
    height: 12px;
    overflow: visible;
}
td  .ColumnContainer:hover .hiddenMore{
        background-color: #ffffff;
        top: 0px;
        left: 0px;
        display : block;
        width: 250px;
        min-height: 100px;
        margin-top: 10px;
        margin-left: -10px;

        height: 100px; 
        border: 1px solid #000000;
        /* z-index: 999;   */
        position:relative; 
}
td .ColumnContainer:hover .ok{
    display : none;
}

td .ColumnContainer .hiddenMore .calendar {
    width: 100%;
}
.highlight_starthere {
    border-top: 4px solid #000;
}
 
  
  /*This will work on every browser but Chrome Browser*/
  .calendar thead {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    /*z-index: 1;*/
    background-color: antiquewhite;
      color: rgb(0, 0, 0);
    
  }
  
  /*This will work on every browser*/
  .calendar thead th {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      /*z-index: 99;*/
      /*background-color: antiquewhite;*/
      /* background-color: #ffffff; */
      color: rgb(0, 0, 0);
  }

  .popup-overlay {
    position: fixed; top: 0px; bottom: 0px; left: 0px; right: 0px; background: rgba(0, 0, 0, 0.5); display: flex; z-index: 99999999;
  }

  .blacktext{
      color: #000;
  }

    .react-datepicker__month-container
    {
        z-index: 999999999;
    }


    .standard_popup, .popup-content {
box-shadow: -9px -5px 15px -6px rgba(0,0,0,0.75);
    }
    .standard_popup, .popup-overlay {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(218, 210, 210, 0.85);
    display: flex;
    z-index: 999;
}
.modal-header {
    background-color: #44514E!important;
    color: #ffffff!important;
    padding: 8px 10px;
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 14px;
}

    .modal-title {
        background-color: rgb(221, 221, 221);
        float: left;
        width: 100%;
        font-size: 25px;
        margin-bottom: 3px;
        padding: 5px;
    }

    .modal-close{
        margin-top: -34px;
    margin-right: 10px;
    font-size: 25px;
    }
    .seachbtn {
        height: 48px!important;
        right: 5px;
        top: -6px;
        border: 0px;
        background-color: #1ebea5!important;
        color: #ffffff!important;
        padding: 12px 22px;
        font-size: 14px;
        font-weight: bold;
    }
/* form/login.css */
@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }

}

.Login {
box-shadow: 4px 6px 21px -10px rgba(0,0,0,0.75);
}
