/* familysocialhostory/style.css */
.col-padding{
    margin-bottom:  5px;
}
.div-margin{
    margin: 20px 0 0 0;
}
.answer-table tr{
    padding: 5px;
}
.answer-table td{
    padding: 5px;
}
.occtext { color:#666666; }
.modal-body label {
    font-size: 12px;
    vertical-align: unset;
}
.col-form-label {
    font-size: 12px;
    vertical-align: unset;
}
.link { margin-top:3px; }
.link a{ color:#ffffff; font-size:14px!important; padding-left:10px!important; margin-left: 10px;  border-left: 1px solid #ffffff; }
.link a:first-child { border:0px; }